import React, {Suspense} from 'react';
import { Route, Switch, Link } from "react-router-dom";
import '../common/css/global.css';
const AviationFestival = React.lazy(() => import("./aviation-festival-2022/aviationfestival2022/pages/LandingPage"));
const AviationFestivalKiosk = React.lazy(() => import("./aviation-festival-2022/aviationfestival2022kiosk/pages/LandingPage"));
const LandingPage = React.lazy(() => import("./aviation-festival-2022/aviationfestivalasia2022/pages/LandingPage"));
const AwardsLandingPage = React.lazy(() => import("./greenawards/pages/LandingPage"));
const SXSWLandingPage = React.lazy(() => import("./sxsw/pages/LandingPage"));
// 2023
const AviationFestivalAsia2023kiosk = React.lazy(() => import("./aviation-festival-2023/aviationfestival2023kiosk/pages/LandingPage"));
const AviationFestival2023 = React.lazy(() => import("./aviation-festival-2023/aviationfestival2023/pages/LandingPage"));

const AviationFestival2023AmericasPublic = React.lazy(() => import("./aviation-festival-2023/Americas/PublicPage/pages/LandingPage.js"));
const AviationFestival2023AmericasKiosk = React.lazy(() => import("./aviation-festival-2023/Americas/KioskPage/pages/LandingPage.js"));

function App() {

  return (
    <>

      <Switch>
        <Route
          path={`/sxsw2022`}
          key="app-sxsw-landing"
        >
          <Suspense fallback={<div>Loading...</div>}>
            <SXSWLandingPage />
          </Suspense>
        </Route>

        <Route
          path={"/aviationfestivalasia2022"}
          key="aviationfestivalasia2022-landing"
        >
          <Suspense fallback={<div>Loading...</div>}>
            <LandingPage merchantCode="BES_NZ_CLIMATE_FRIENDLY_TRAVELLER" />
          </Suspense>
        </Route>

        <Route
          path={"/worldaviationfestival2022"}
          key="worldaviationfestival2022-landing"
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AviationFestival merchantCode="BES_NZ_CARBONCLICK_WORLD_AVIATION_FESTIVAL" />
          </Suspense>
        </Route>

        <Route
          path={"/worldaviationfestival2022kiosk"}
          key="aviationfestivalkiosk2022-landing"
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AviationFestivalKiosk merchantCode="BES_NZ_CARBONCLICK_WORLD_AVIATION_FESTIVAL" />
          </Suspense>

        </Route>

        <Route
          path={"/greenawards"}
          key="greenawards-landing"
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AwardsLandingPage merchantCode="BES_IE_GREEN_AWARDS" />
          </Suspense>
        </Route>

         {/* 2023 */}
        <Route
          path={"/aviationfestivalasia2023kiosk"}
          key="aviationFestivalAsia2023kiosk-landing"
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AviationFestivalAsia2023kiosk merchantCode="BES_NZ_CARBONCLICK_AVIATION_FESTIVAL_ASIA_2023" />
          </Suspense>
        </Route>

        <Route
          path={"/aviationfestivalasia2023"}
          key="aviationfestivalasia2023-landing"
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AviationFestival2023 merchantCode="BES_NZ_CARBONCLICK_AVIATION_FESTIVAL_ASIA_2023" />
          </Suspense>
        </Route>

        {/* Americas */}

        <Route
          path={"/aviationfestivalamericas2023"}
          key="aviationfestivalamericas2023-landing"
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AviationFestival2023AmericasPublic merchantCode="BES_NZ_AVIATION_FESTIVAL_AMERICAS_2023" />
          </Suspense>
        </Route>

        <Route
          path={"/aviationfestivalamericas2023kiosk"}
          key="aviationFestivalAmericas2023kiosk-landing"
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AviationFestival2023AmericasKiosk merchantCode="BES_NZ_AVIATION_FESTIVAL_AMERICAS_2023" />
          </Suspense>
        </Route>

        <Route>
            {()=>window.location="https://www.carbonclick.com"}
        </Route>
      </Switch>
    </>
  );
}

export default App;
